.txt-center {
  text-align: center !important;
}

.container-header{
  display: flex !important;
  flex-direction: column !important;
}
.back-btn{
  width: 220px !important;
  margin: 1% !important;
}

.display-flex {
  display: flex;
}

.file-name {
  margin-left: 7%;
  width: 52%;
}

.subfile-name {
  margin-left: 5%;
  width: 52%;
}

.filename-input {
  padding: 8px;
  width: 52%;
}

.upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-upload {
  width: 20rem;
}

.progress {
  width: 100%;
}

.multi-select {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1%;
}

.lang-select {
  height: 65% !important;
  margin-left: 5% !important;
  min-width: 35% !important;
}

.upload-satus {
  font-size: 0.975rem !important;
  margin-left: 2% !important;
}

.color-red {
  color: red !important;
}

.color-green {
  color: green !important;
}